import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormFieldService {

  private personalInfoFormData = new BehaviorSubject<any>(null);
  private dropImageData = new BehaviorSubject<any>(null);

  constructor() { }

  getPersonalInfoFormData() {
    return this.personalInfoFormData.asObservable();
  }

  setPersonalInfoFormData(data: any) {
    this.personalInfoFormData.next(data);
  }

  getDropImageData() {
    return this.dropImageData.asObservable();
  }

  setDropImageData(data: any) {
    this.dropImageData.next(data);
  }

  clearAllData() {
    this.personalInfoFormData.next(null); // Reset personal info form data
    this.dropImageData.next(null); // Reset drop image data
  }
}
